import client from '@lib/sanity';
import Img, { ImageProps } from 'next/image';
import { useNextSanityImage } from 'next-sanity-image';
import React, { useState, useRef, useEffect } from 'react';

interface IImage {
  sanityImage: {
    asset: {
      _ref: string,
      _type: string,
    },
    crop?: {
      _type: string,
      top: number,
      bottom: number,
      left: number,
      right: number,
    },
    hotspot?: {
      _type: string,
      x: number,
      y: number,
      height: number,
      width: number,
    },
    _type: string,
    alt: string,
  }
  staticSrc?: string,
  className?: string,
  layout?: 'intrinsic' | 'responsive' | 'fill' | 'fixed',
  priority?: ImageProps['priority'],
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down',
  width?: ImageProps['width'],
  height?: ImageProps['height'],
  sizes?: ImageProps['sizes'],
}

const styling = {
  /* TODO: Refactor the image size shouldn't be always the same */
  image: 'w-full h-auto',
};

const defaultMaxWidth = 1440;
const defaultMaxHeight = 960;

const customImageBuilder = (
  imageUrlBuilder: any,
  options: any,
  elementWidth: number = defaultMaxWidth,
  elementHeight: number = defaultMaxHeight,
  objectFit: string|undefined
) => {
  const ratio = options.croppedImageDimensions.aspectRatio;
  const neededWidth = objectFit === 'contain' ? 0 : elementHeight * ratio;
  elementWidth = Math.round(Math.max(elementWidth, neededWidth));
  options.width= Math.min(Math.floor(options.croppedImageDimensions.width), elementWidth, 1440);

  return imageUrlBuilder
    .width(options.width)
    .quality(75)
    .fit('clip');
};

export const Image = ({
  sanityImage,
  width,
  height,
  layout = 'intrinsic',
  objectFit,
  className,
  priority = true,
  sizes,
}: IImage) => {
  const ref = useRef<HTMLDivElement>(null);

  const [ elementWidth, setElementWidth ] = useState(width || defaultMaxWidth);
  const [ elementHeight, setElementHeight ] = useState(height || defaultMaxHeight);

  useEffect(() => {
    const imgElement = (ref.current?.firstChild as HTMLElement);

    setElementWidth(imgElement.offsetWidth || defaultMaxWidth);
    setElementHeight(imgElement.offsetHeight || defaultMaxHeight);
  }, []);

  const imageProps = useNextSanityImage(
    client,
    sanityImage,
    { imageBuilder: (imageBuilder, options) => {
      return customImageBuilder(imageBuilder, options, elementWidth as number, elementHeight as number, objectFit);
    },
    }
  );

  const getSanityProps = () => {
    let style = { objectPosition: 'center' };
    if (sanityImage.hotspot && objectFit !== 'contain') {
      // Move to left top of hotspot
      // const { height, width, x, y } = image.hotspot;
      // const left = (x - (width / 2)) * 100 + '%';
      // const top = (y - (height / 2)) * 100 + '%';
      // objectPosition = ` ${ left } ${ top }`;

      const { x, y } = sanityImage.hotspot;
      const left = x  * 100;
      const top = y * 100;
      style = { objectPosition: `${ left }% ${ top }%` };
    }
    const { blurDataURL, placeholder, width, height, src } = imageProps;

    return layout === 'fill'
      ? { blurDataURL, placeholder, style, src }
      : { blurDataURL, placeholder, width, height, src };
  };

  // By default NextJS routes images through it's own server. This removes metadata and tries to scale the image.
  // Since Sanity CDN already crops the image this is not needed and can interfere with the scaling that was already
  // done by Sanity.
  const loader = ({ src, width, height, quality }): string => {
    const url = new URL(src);
    if (width) url.searchParams.set('w', width);
    if (height) url.searchParams.set('h', height);
    if (quality) url.searchParams.set('q', quality);
    return url.toString();
  };

  return (
    <div
      ref={ ref }
      className={
        layout === 'fill' ? 'relative w-full h-full'
          : layout === 'responsive' ? 'block': ''
      }
    >
      <Img
        { ...getSanityProps() }
        alt={ sanityImage.alt }
        className={ `${ styling.image } ${ className ?? '' } object-${ objectFit }` }
        fill={ layout === 'fill' }
        priority={ priority }
        sizes={ sizes }
        loader={ loader }
        unoptimized={ true } // This is already optimized by Sanity
      />
    </div>
  );
};
