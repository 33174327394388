import Button from '@core/Button';
import Dropdown from '@core/Dropdown';
import Input from '@core/Input';

export default function Filters({
  position,
  options,
  selectedProductType,
  selectedLocation,
  emit,
  buttonIsDisabled,
  buttonLabel,
  disableLocation,
}) {

  /* Parse to dropdown options */
  const productOptions = Object.entries(options.productTypes).map(entry => {
    return {
      label: entry[1] as string,
      value: entry[0],
    };
  });
  productOptions.unshift({
    label: 'Selecteer alles',
    value: 'ALL',
  });

  return (
    <>
      <div className='relative w-full z-30 mr-4 mb-2 xl:mb-0 min-w-[213px]'>
        <Dropdown
          id={ `products-filter-${ position }` }
          options={ productOptions }
          isMulti
          closeMenuOnSelect={ false }
          onChange={ (selected) => {
            if ( selected && Array.isArray(selected) && selected.length > 0) {
              const hasSelectedAll = selected.find(option => option.value === 'ALL') !== undefined;
              emit.productType(hasSelectedAll ? productOptions.slice(1) : selected);
            } else {
              emit.productType(null);
            }
            emit.disableApplyButton(false);
          } }
          placeholder={ 'Kies type opvang' }
          value={ selectedProductType }
        />
      </div>
      <div className='relative w-full z-10 mr-4 mb-4 xl:mb-0'>
        <Input
          name='location'
          value={ selectedLocation.value }
          isValid={ selectedLocation.isValid }
          showErrorMessage={ selectedLocation.showErrorMessage }
          emitOnChange={ emit.location }
          validState={ selectedLocation.validState }
          validation={ {
            pattern: {
              errorMessage: 'Geen geldige locatie',
            },
          } }
          placeholder='Zoek op plaats of postcode'
          onEnter={ emit.applyFilter }
          disabled={ disableLocation }
        />
      </div>
      <Button
        className='whitespace-nowrap self-start'
        onClick={ emit.applyFilter }
        disabled={ buttonIsDisabled }
      >
        { buttonLabel }
      </Button>
    </>
  );
}
