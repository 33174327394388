import { getClient } from '@lib/sanity';

export async function fetchSingleLocationProducts(lovale, preview = false, slug) {
  return await getClient(preview).fetch(`
    *[_type == 'page' && slug.current =='${ slug }'][0]{
      sectionBox[@._type == 'multiContactSection'] {
        products[] {
          productName
        }
      }
    }.sectionBox[0].products
  `);
}

export async function fetchKinderopvangLocationsNames(locale, preview = false) {

  return await getClient(preview).fetch(`
    *[ _type == "page" && !(_id in path("drafts.**")) && type =="locatie" && __i18n_lang == "${ locale }" && level == 3 && domain == "kinderopvang"] {
      pageTitle,
      locationCode,
      'products': sectionBox[@._type == 'multiContactSection'] {
        products[] {
          productName,
          'city': contactDetails {
            city
          }.city
        }
      }.products[]
    }
  `);
}

export async function fetchKinderopvangLocations(locale, preview = false) {

  return await getClient(preview).fetch(`
    *[ _type == "page" && !(_id in path("drafts.**")) && type == "locatie" && __i18n_lang == "${ locale }" && level == 3 && domain == "kinderopvang"] {
      _id,
      pageTitle,
      slug,
      geoLocation {
        lat,
        lng
      },
      locationCode,
      cardImage,
      __i18n_lang,
      sectionBox[@._type == 'multiContactSection'] {
        ...
      }
    }
  `);
}

export async function fetchLocations(locale, preview = false) {
  return await getClient(preview).fetch(`
    *[ _type == "page" && !(_id in path("drafts.**")) && type == "locatie" && __i18n_lang == "${ locale }" && level == 3 && domain == "kinderopvang"] {
      _id,
      pageTitle,
      slug,
      geoLocation {
        lat,
        lng
      },
      locationCode,
      cardImage,
      __i18n_lang,
      sectionBox[@._type == 'multiContactSection'] {
        ...
      },
      parentSlug,
    } | order(lower(pageTitle) asc)
  `);
}

export async function fetchLocationsByCity(locale, city, preview = false) {
  return await getClient(preview).fetch(`
    *[ _type == "page" && !(_id in path("drafts.**")) && type == "locatie" && __i18n_lang == "${ locale }" && level == 3 && domain == "kinderopvang" && parentSlug == "${ city }"] {
      _id,
      pageTitle,
      slug,
      geoLocation {
        lat,
        lng
      },
      locationCode,
      cardImage,
      __i18n_lang,
      sectionBox[@._type == 'multiContactSection'] {
        ...
      },
      parentSlug,
    } | order(lower(pageTitle) asc)
  `);
}
