import { ChangeEvent, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Button from '@core/Button';
import Input from '@core/Input';
import Grid from '@core/layout/Grid';
import Dropdown from '@core/Dropdown';
import Icon from '@core/Icon';
import { fetchKinderopvangLocationsNames } from '@services/location';

import { notEmpty, isEmail, isPhoneNumber } from '@helpers/string';

const styling = {
  errorMessage: `
    text-body-sm
    text-left
    text-error
  `,
  checkbox: `
    peer
    block
    appearance-none
    cursor-pointer
    rounded-sm
    w-5
    h-5
    min-w-5
    border
    border-shade-300

    checked:bg-primary
    checked:border-primary
    hover:border-primary-300
    hover:bg-primary-50
  `,
};

interface ITourguideForm {
  title: string,
  intro: string,
  emailSubject: string,
  recipientEmail: string,
  submitButtonLabel: string,
  responseBody: string,
  responseTitle: string
  locale: string
}

const lookUps = {
  kinderopvang: {
    BSO: 'Buitenschoolse opvang',
    POV: 'Peuteropvang',
    KDV: 'Kinderdagverblijf',
    // ZJK: 'Zorg Jonge Kind',
    // GOB: 'Gastouderbureau',
  },
};

const daysOptions  = [
  {
    label: 'maandag',
    value: 'maandag',
  },
  {
    label: 'dinsdag',
    value: 'dinsdag',
  },
  {
    label: 'woensdag',
    value: 'woensdag',
  },
  {
    label: 'donderdag',
    value: 'donderdag',
  },
  {
    label: 'vrijdag',
    value: 'vrijdag',
  },
];

/* Parse to dropdown options */
const productOptions = Object.entries(lookUps.kinderopvang).map(entry => {
  return {
    label: entry[1] as string,
    value: entry[0],
  };
});

const defaultFieldState = {
  value: '',
  isValid: false,
  validState: {}, // key: type of validation, value: boolean
  showErrorMessage: false,
};

export default function TourguideForm({
  title,
  intro,
  emailSubject,
  recipientEmail,
  submitButtonLabel,
  responseBody,
  responseTitle,
}: ITourguideForm) {

  const [ formState, setFormState ] = useState<{[key: string]: any}>({
    firstName: { ...defaultFieldState },
    preposition: { ...defaultFieldState },
    lastName: { ...defaultFieldState },
    email: { ...defaultFieldState },
    phone: { ...defaultFieldState },

    cityOfInterest: { ...defaultFieldState },
    productOfInterest: { ...defaultFieldState },
    locationOfInterest: { ...defaultFieldState },

    requestDay: { ...defaultFieldState },
    openQuestion: { ...defaultFieldState },
    termsAccepted: {
      value: '',
      isValid: false,
      showErrorMessage: false,
    },
    recaptcha: {
      value: '',
      isValid: false,
      showErrorMessage: false,
    },
  });

  const [ loading, setLoading ] = useState(false);
  const [ submitted, setSubmitted ] = useState(false);
  const [ formErrorMessage, setFormErrorMessage ] = useState('');
  const [ locationOptions, setLocationsOptions ] = useState(null);
  const [ cityOptions, setCityOptions ] = useState([]);

  const responseRef = useRef(null);

  const fetchLocations = useCallback(async() => {
    let result = await fetchKinderopvangLocationsNames('nl', false);

    // Tijdelijk filter voor de rondleiding van twee vestigingen
    result = result.filter((location) => {
      return location.locationCode !== 'D-SATTELLIET' && location.locationCode !== 'BONTE SPECHT';
    });
    const newCitypOptions = result.reduce((prev, location) => {
      if (Array.isArray(location.products) && location.products.length > 0 && location.products[0].city ) {
        if (!Array.isArray(prev)) {
          return [ { label: location.products[0].city.trim(), value: location.products[0].city.trim() } ];
        }
        if (prev.some((option) => option.label === location.products[0].city.trim())) {
          return prev;
        } else {
          prev.push({ label: location.products[0].city.trim(), value: location.products[0].city.trim() });
          return prev;
        }
      } else {
        return prev;
      }
    }, [ ]).sort((a, b) => a.label.localeCompare(b.label));
    setCityOptions(newCitypOptions);
    setLocationsOptions(result.map(location => ({ label: location.pageTitle, value: location })));
  }, []);

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    if (submitted === true) {
      setTimeout(() => {
        window.scrollTo({ top: (responseRef?.current?.getBoundingClientRect().top || 0) + window.pageYOffset - 270, behavior: 'smooth' });
      }, 50);
    }
  }, [ submitted ]);

  const filteredCityOptions = useMemo(() => {
    const newCityOptions =  cityOptions.filter((option) => {
      if (
        formState.locationOfInterest &&
        formState.locationOfInterest.value &&
        formState.locationOfInterest.value.products &&
        Array.isArray(formState.locationOfInterest.value.products)
      ) {
        let hasCity = false;
        for (const product of formState.locationOfInterest.value.products) {
          if (product?.city?.trim() === option.value.trim()) {
            hasCity = true;
            break;
          }
        }
        return hasCity;
      } else {
        return true;
      }
    });

    return newCityOptions;
  }, [ formState, cityOptions ]);

  const filteredLocations = useMemo(() => {
    if (locationOptions) {
      const montrisLocations = locationOptions.filter((location) => location.label.startsWith('Montris'));
      const newLocationOptions =  locationOptions
        .filter((location) => !location.label.startsWith('Montris'))
        .sort((a, b) => a.label.localeCompare(b.label))
        .concat(montrisLocations)
        .filter((location) => { // filter on selected product
          if (!formState.productOfInterest.value?.value || formState.productOfInterest.value.value === 'Alle types') {
            return true;
          }
          let hasSelectedProduct = false;
          if (location.value?.products && location.value?.products.length > 0) {

            for (const product of location.value?.products) {
              if (product.productName === formState.productOfInterest.value.value) {
                hasSelectedProduct = true;
              }
            }

          }
          return hasSelectedProduct;
        })
        .filter((location) => { // filter on selected city
          if (!formState.cityOfInterest.value?.value || formState.cityOfInterest.value.value === 'Alle plaatsen') {
            return true;
          }
          let hasSelectedCity = false;
          if (location.value?.products && location.value?.products.length > 0) {
            for (const product of location.value?.products) {
              if (product.city?.trim() === formState.cityOfInterest.value.value.trim()) {
                hasSelectedCity = true;
              }
            }

          }
          return hasSelectedCity;
        });
      return newLocationOptions;
    }
  },[ locationOptions, formState ]);

  const filteredProductOptions = useMemo(() => {
    const newProductOptions =  productOptions.filter((option) => {
      if (
        formState.locationOfInterest &&
        formState.locationOfInterest.value &&
        formState.locationOfInterest.value.products &&
        Array.isArray(formState.locationOfInterest.value.products)
      ) {
        let hasProduct = false;
        for (const product of formState.locationOfInterest.value.products) {
          if (product?.productName === option.value) {
            hasProduct = true;
            break;
          }
        }
        return hasProduct;
      } else if (formState.cityOfInterest && formState.cityOfInterest.value && formState.cityOfInterest.value !== 'Alle plaatsen') {
        let hasProduct = false;
        for (const location of filteredLocations) {
          if (location.value.products) {
            location.value.products.forEach((product) => {
              if (product.productName === option.value) {
                hasProduct = true;
              }
            });
          }
          if (hasProduct) {
            break;
          }
        }
        return hasProduct;
      }
      else {
        return true;
      }
    });
    return newProductOptions;
  }, [ formState ]);

  const submitContactForm = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    const formElement = (e.target as HTMLFormElement);
    if (loading) {
      return;
    }

    let errorFound = false;

    for (const fieldName in formState) {
      if (!formState[fieldName].isValid) {
        setFormState((prevState: typeof formState) => {
          const nextState = Object.assign({}, prevState);

          nextState[fieldName].showErrorMessage = true;

          return nextState;
        });

        errorFound = true;
      }
    }

    if (errorFound) {
      setTimeout(() => {
        const errorElement: HTMLElement|null = formElement.querySelector('[data-error="true"]');
        if (errorElement) {
          errorElement.focus();
        }
      }, 50);

      return;
    }

    const data = {
      firstName: formState.firstName.value,
      lastName: formState.lastName.value,
      preposition: formState.preposition.value,
      openQuestion: formState.openQuestion.value,
      email: formState.email.value,
      phone: formState.phone.value,

      cityOfInterest: formState.cityOfInterest.value.value,
      productOfInterest: formState.productOfInterest.value.value,
      locationOfInterest: formState.locationOfInterest.value.pageTitle ?? formState.locationOfInterest.value,
      locationOfInterestCode: formState.locationOfInterest.value.locationCode,
      requestDay: formState.requestDay.value.value,

      recipientEmail: recipientEmail,
      emailSubject: emailSubject,
      formLocation: window.location.href,
      recaptcha: formState.recaptcha.value,
    };

    setLoading(true);
    setFormErrorMessage('');

    fetch('/api/tourguide-form-email', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
      // Response received
        setLoading(false);
        if (res.status === 200 || res.status === 201) {
        // Response succeeded!
          setSubmitted(true);
          return;
        }
        return res.text();
      })
      .then(errorMessage => {
        setFormErrorMessage('Fout tijdens het verzenden. Probeer het opnieuw!');
        console.error(errorMessage);
      })
      .catch(error => {
        setFormErrorMessage('Fout tijdens het verzenden. Probeer het opnieuw!');
        console.error(error);
      });

  }, [ loading, formState, recipientEmail, emailSubject ]);

  const updateFormState = (fieldName: keyof typeof formState, key: 'value'|'isValid'|'validState'|'showErrorMessage', value: any) => {
    setFormState((prevState: typeof formState) => {
      const nextState = Object.assign({}, prevState);

      // Allow state to be changed for all keys
      nextState[fieldName][key] = value;

      // Clear error message after changing value
      if (key === 'value') {
        nextState[fieldName]['errorMessage'] = '';
      }

      return nextState;
    });
  };

  const handleEmitOnChange = (e: ChangeEvent, fieldName: keyof typeof formState) => {
    updateFormState(fieldName, 'value', (e.target as HTMLInputElement).value);
    updateFormState(fieldName, 'showErrorMessage', false);
  };

  const handleEmitValidate = (validState: { [key: string]: boolean }, fieldName: keyof typeof formState) => {
    let isValid = true;
    for (const validateKey in validState) {
      if (!validState[validateKey]) {
        isValid = false;
        break;
      }
    }
    updateFormState(fieldName, 'isValid', isValid);
    updateFormState(fieldName, 'validState', validState);
  };

  return (
    <Grid>
      <div className='col-span-8 col-start-3' ref={ responseRef }>
        {
          submitted && !loading &&
          <div id='rondleiding-formulier-succes'>
            <h2 className='text-heading-md md:text-heading-lg mb-4'>{ responseTitle }</h2>
            <div className='text-body-lg md:text-body-xl mb-6 md:mb-8 xl:mb-12'>{ responseBody }</div>
          </div>
        }
        {
          !submitted &&
          <div>
            <h2 className='text-heading-md md:text-heading-lg mb-4'>{ title }</h2>
            <div className='text-body-lg md:text-body-xl mb-6 md:mb-8 xl:mb-12'>{ intro }</div>
          </div>
        }
        { !submitted && (
          <form onSubmit={ submitContactForm } className={ loading ? 'opacity-50 pointer-events-none' : '' } id='rondleiding-formulier'>
            <h3 className='text-heading-xs md:text-heading-sm mb-4'>Algemene informatie</h3>
            <div className='flex flex-col gap-y-4'>
              <Input
                label='Voornaam'
                required
                value={ formState.firstName.value }
                isValid={ formState.firstName.isValid }
                showErrorMessage={ formState.firstName.showErrorMessage }
                emitOnChange={ (e) => handleEmitOnChange(e, 'firstName') }
                emitOnValidate={ (e) => handleEmitValidate(e, 'firstName') }
                validState={ formState.firstName.validState }
                validation={ {
                  required: {
                    errorMessage: 'Je hebt nog geen naam ingevuld',
                  },
                } }
                customStyling={ {
                  container: {
                    className: 'grow',
                  },
                } }
              />
              <div className='flex-col flex md:flex-row gap-x-4'>
                <Input
                  label='Tussenvoegsel'
                  value={ formState.preposition.value }
                  isValid={ formState.preposition.isValid }
                  emitOnChange={ (e) => handleEmitOnChange(e, 'preposition') }
                  emitOnValidate={ (e) => handleEmitValidate(e, 'preposition') }
                  validState={ formState.preposition.validState }
                  customStyling={ {
                    container: {
                      className: 'md:max-w-[165px] mb-4 md:mb-0',
                    },
                  } }
                />
                <Input
                  label='Achternaam'
                  required
                  value={ formState.lastName.value }
                  isValid={ formState.lastName.isValid }
                  showErrorMessage={ formState.lastName.showErrorMessage }
                  emitOnChange={ (e) => handleEmitOnChange(e, 'lastName') }
                  emitOnValidate={ (e) => handleEmitValidate(e, 'lastName') }
                  validState={ formState.lastName.validState }
                  validation={ {
                    required: {
                      errorMessage: 'Je hebt nog geen achternaam ingevuld',
                    },
                  } }
                  customStyling={ {
                    container: {
                      className: 'grow',
                    },
                  } }
                />
              </div>
              <div className='flex-col flex md:flex-row gap-x-4'>
                <Input
                  label='E-mailadres'
                  required
                  value={ formState.email.value }
                  isValid={ formState.email.isValid }
                  showErrorMessage={ formState.email.showErrorMessage }
                  emitOnChange={ (e) => handleEmitOnChange(e, 'email') }
                  emitOnValidate={ (e) => handleEmitValidate(e, 'email') }
                  validState={ formState.email.validState }
                  validation={ {
                    required: {
                      validateFunction: notEmpty,
                      errorMessage: 'Je hebt nog geen e-maildres ingevuld',
                    },
                    pattern: {
                      validateFunction: isEmail,
                      errorMessage: 'Je hebt een ongeldig e-mailadres ingevuld. Kun je het controleren?',
                    },
                  } }
                  customStyling={ {
                    container: {
                      className: 'grow mb-4 md:mb-0',
                    },
                  } }
                />
                <Input
                  label='Telefoonnummer'
                  required
                  value={ formState.phone.value }
                  isValid={ formState.phone.isValid }
                  showErrorMessage={ formState.phone.showErrorMessage }
                  emitOnChange={ (e) => handleEmitOnChange(e, 'phone') }
                  emitOnValidate={ (e) => handleEmitValidate(e, 'phone') }
                  validState={ formState.phone.validState }
                  validation={ {
                    pattern: {
                      validateFunction: isPhoneNumber,
                      errorMessage: 'Dit is geen geldig telefoonnummer. Kun je het controleren?',
                    },
                  } }
                  placeholder='bv: 0101234567/0612345678'
                  customStyling={ {
                    container: {
                      className: 'grow',
                    },
                  } }
                />
              </div>
              <h3 className='text-heading-xs md:text-heading-sm mt-2 md:mt-4'>Locatie van interesse</h3>
              <div className='flex-col flex md:flex-row md:flex-wrap gap-4'>
                <Dropdown
                  id='city-of-interest'
                  label='Plaats'
                  isMulti={ false }
                  options={ filteredCityOptions }
                  isSearchable={ true }
                  onChange={ (selected) => {
                    updateFormState('cityOfInterest', 'value', selected);
                    updateFormState('cityOfInterest', 'isValid', selected?.value?.length > 0);
                    updateFormState('cityOfInterest', 'showErrorMessage', selected?.value?.length === 0);
                  } }
                  placeholder={ '-Kies plaats-' }
                  value={ formState.cityOfInterest.value }
                  errorMessage='Selecteer een plaats'
                  showErrorMessage={ formState.cityOfInterest.showErrorMessage }
                  className='grow z-40 md:whitespace-nowrap min-w-[310px] max-w-[330px]'
                />
                <Dropdown
                  id='product-of-interest'
                  label='Type opvang'
                  isMulti={ false }
                  options={ filteredProductOptions }
                  onChange={ (selected) => {
                    updateFormState('productOfInterest', 'value', selected);
                    updateFormState('productOfInterest', 'isValid', selected?.value?.length > 0);
                    updateFormState('productOfInterest', 'showErrorMessage', selected?.value?.length === 0);
                  } }
                  placeholder={ '-Kies type opvang-' }
                  value={ formState.productOfInterest.value }
                  errorMessage='Selecteer een type opvang'
                  showErrorMessage={ formState.productOfInterest.showErrorMessage }
                  className='grow z-30 md:whitespace-nowrap min-w-[310px] max-w-[330px]'
                  data-error={ !formState.cityOfInterest.isValid }
                />
                <Dropdown
                  id='location-of-interest'
                  label='Vestiging'
                  isSearchable={ true }
                  isMulti={ false }
                  options={ filteredLocations }
                  onChange={ (selected) => {
                    updateFormState('locationOfInterest', 'value', selected?.value);
                    updateFormState('locationOfInterest', 'isValid', selected?.label.length > 0);
                    updateFormState('locationOfInterest', 'showErrorMessage', selected?.label.length === 0);
                  } }
                  placeholder={ '-Kies vestiging-' }
                  value={ formState.locationOfInterest.label }
                  errorMessage='Selecteer een vestiging'
                  showErrorMessage={ formState.locationOfInterest.showErrorMessage }
                  className='grow z-20 md:whitespace-nowrap min-w-[310px] max-w-[330px]'
                />
              </div>
              <h3 className='text-heading-xs md:text-heading-sm mt-2 md:mt-4'>Rondleiding</h3>
              <Dropdown
                id='day-of-interest'
                label='Op welke dag wilt u een rondleiding?'
                options={ daysOptions }
                isMulti={ false }
                onChange={ (selected) => {
                  updateFormState('requestDay', 'value', selected);
                  updateFormState('requestDay', 'isValid', selected.value.length > 0);
                  updateFormState('requestDay', 'showErrorMessage', selected.value.length === 0);
                } }
                placeholder={ '-Kies dag-' }
                value={ formState.requestDay.value }
                errorMessage='Selecteer een dag'
                showErrorMessage={ formState.requestDay.showErrorMessage }
                className='z-10'
              />
              <Input
                type='textarea'
                placeholder='Stel hier uw vraag...'
                label='Overige vragen'
                value={ formState.openQuestion.value }
                isValid={ formState.openQuestion.isValid }
                showErrorMessage={ formState.openQuestion.showErrorMessage }
                emitOnChange={ (e) => handleEmitOnChange(e, 'openQuestion') }
                emitOnValidate={ (e) => handleEmitValidate(e, 'openQuestion') }
                validState={ formState.openQuestion.validState }
                customStyling={ {
                  container: {
                    className: 'grow',
                  },
                } }
              />
              <div>
                <div className='flex items-center mt-2 relative'>
                  <input
                    type='checkbox'
                    id='termsAccepted'
                    checked={ !!formState.termsAccepted.value }
                    className={ styling.checkbox + ' flex-none' }
                    onChange={ (e) => {
                      updateFormState('termsAccepted', 'value', e.target.checked);
                      updateFormState('termsAccepted', 'isValid', e.target.checked);
                      updateFormState('termsAccepted', 'showErrorMessage', false);
                    } }
                  />
                  { !!formState.termsAccepted.value && (
                    <span className='absolute left-[3px] pointer-events-none peer-checked:text-white peer-hover:text-primary'>
                      <Icon name='Done' className='!w-[13.58px]' />
                    </span>
                  ) }
                  <label
                    htmlFor='termsAccepted'
                    className='pl-4 cursor-pointer select-none'
                  >
                    Ik geef Prokino toestemming om mij, nadat de rondleiding heeft plaastgevonden, te mogen bellen of mailen.
                  </label>
                </div>
                { !formState.termsAccepted.isValid && formState.termsAccepted.showErrorMessage && (
                  <span className={ styling.errorMessage }>Je hebt nog geen toestemming gegeven</span>
                ) }
              </div>
              <div className='relative z-10 mt-4 mb-2 md:mb-4 xl:mb-8 max-w-[305px]'>
                <ReCAPTCHA
                  sitekey={ process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY }
                  onChange={ (captchaCode) => {
                    if(!captchaCode) {
                      updateFormState('recaptcha', 'value', false);
                      updateFormState('recaptcha', 'isValid', false);
                      return;
                    }
                    updateFormState('recaptcha', 'value', captchaCode);
                    updateFormState('recaptcha', 'isValid', true);
                  } }
                  onExpired={ () => {
                    updateFormState('recaptcha', 'value', false);
                    updateFormState('recaptcha', 'isValid', false);
                  } }
                />
                { !formState.recaptcha.isValid && formState.recaptcha.showErrorMessage && (
                  <span className={ styling.errorMessage }>Controleer of je geen robot bent.</span>
                ) }
              </div>
              <div>
                <Button disabled={ loading }>
                  <span>{ submitButtonLabel }</span>
                </Button>
                { formErrorMessage && (
                  <span className={ `${ styling.errorMessage } md:w-1/2 ml-2` }>Fout tijdens het verzenden. Probeer het opnieuw!</span>
                ) }
              </div>
            </div>
          </form>
        ) }
      </div>
    </Grid>
  );
}
