import Grid from '@components/core/layout/Grid';
import RichText from '@components/core/RichText';
import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import Filters from '@components/Filters';

export default function HeaderKOVFilter(props: any) {
  const {
    heading,
    intro,
  } = props;

  const lookUps = {
    kinderopvang: {
      BSO: 'Buitenschoolse opvang',
      POV: 'Peuteropvang',
      KDV: 'Kinderdagverblijf',
      ZJK: 'Zorg Jonge Kind',
    },
  };

  const [ selectedProductType, setProductType ] = useState(null);
  const [ selectedLocation, setLocation ] = useState({
    value: '',
    isValid: true,
    validState: { }, // key: type of validation, value: boolean
    showErrorMessage: false,
  });

  const [ buttonIsDisabled, setButtonIsDisabled ] = useState(true);
  const router = useRouter();

  const handleEmitLocation = (e) => {
    setLocation({
      value: e.target.value ,
      isValid: true,
      validState: { }, // key: type of validation, value: boolean
      showErrorMessage: false,
    });
    setButtonIsDisabled(false);
  };

  const applyFilter = useCallback(() => {
    // Convert to url friendly instead of options friendly
    const selectedProductValues = selectedProductType?.map( productType => productType.value).join();
    router.push({
      pathname: 'kinderopvang-locaties',
      query: {
        ...(selectedProductValues ? { productType: selectedProductValues } : {}),
        ...(selectedLocation?.value ? { location: selectedLocation.value } : {}),
      },
    }, undefined, { scroll: false });
  }, [ router, selectedProductType, selectedLocation ]);

  return (
    <>
      <Grid className='pt-8 md:pt-12 xl:pt-16 pb-8 md:pb-12 xl:pb-24'>
        <div className='col-span-12 md:text-center'>
          <h1 className='text-heading-lg mb-2 xl:text-heading-xl xl:mb-4 2xl:text-heading-3xl'>
            { heading }
          </h1>
        </div>
        <div className='col-span-12 mb-4 md:mb-8 md:text-center xl:col-start-2 xl:col-span-10 2xl:col-start-3 2xl:col-span-8'>
          <RichText text={ intro?.text } />
        </div>
        <div className='col-span-12 md:col-span-10 md:col-start-2 p-4 bg-primary-100 rounded-[30px] xl:rounded-[60px]'>
          <div className='flex flex-col xl:flex-row'>
            <Filters
              position='header'
              options={ {
                productTypes: lookUps.kinderopvang,
              } }
              selectedProductType={ selectedProductType }
              selectedLocation={ selectedLocation }
              buttonIsDisabled={ buttonIsDisabled }
              emit={ {
                productType: setProductType,
                location: handleEmitLocation,
                applyFilter,
                disableApplyButton: setButtonIsDisabled,
              } }
              buttonLabel='Bekijk locaties'
              disableLocation={ false }
            />
          </div>
        </div>
      </Grid>
    </>
  );
}
